#sbf-overlay {
        display: none;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        overflow-x: auto;
        overflow-y: scroll;
        z-index: 4000;
        background: transparent;
        filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#50000000,endColorstr=#50000000);
        background: rgba(0,0,0,0.5);
        -webkit-overflow-scrolling: touch;
        box-sizing: content-box;
}

#sbf-overlay .inner {
        width: 920px;
}

@media (max-width: 979px){
        #sbf-overlay .inner {
                width: auto;
        }
}

body.sbf-overlay-mode {
        overflow: hidden;
        overflow-y: hidden;
        overflow-x: hidden;
}

.sbf-overlay-content {
        margin: 20px auto;
        background: white;
        border-radius: 5px;
        -moz-border-radius: 12px;
        width: auto;
        max-width: 780px;
        padding: 20px;
        display: none;
        position: relative;
        border: none;
}

.sbf-overlay-content.sbf-overlay-loading {
        min-height: 150px;
}

.sbf-overlay-content div.loading-indicator {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1;
        display: none;
}

.sbf-overlay-content.sbf-overlay-loading div.loading-indicator {
        display: inherit;
}

.sbf-overlay-content div.loading-indicator div.mask {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: #e7e7e7;
        opacity: 0.5;
}

.sbf-overlay-content div.loading-indicator div.spinner {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: url('/php_legacy/icons/spinner-round.gif') no-repeat 50% 50%;
        z-index: 2;
}

.sbf-overlay-content a.close-button {
        position: absolute;
        top: -20px;
        right: -20px;
        background: url('overlay_close.png') no-repeat;
        display: block;
        width: 35px;
        height: 35px;
        text-indent: -999em;
        border-bottom: none;
}

.sbf-overlay-content h4 {
        background-color: #E6AD30;
   border-bottom: 3px solid #B3965D;
   border-radius: 5px 5px 0 0;
   color: white;
   text-shadow: rgba(0,0,0,0.25) 0px 1px 0px;
   font-size: 23px;
   font-size: 1.432rem;
   font-weight: 700;
   margin: -20px -20px 25px;
   padding: 10px;
   padding: .618rem;
   text-align: center;
}

.sbf-overlay-content h5 {
        font-size: 20px;
        margin-bottom: 5px;
}

.sbf-overlay-content h6 {
        font-size: 1em;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 3px;
}

.sbf-overlay-content fieldset.controls {
        text-align: right;
}

.sbf-overlay-content div.buttonsContainer {
        clear: both;
        padding: 20px 0;
}

div#sbf-overlay-quick-message-content h3 {
        margin-top: 10px;
        margin-bottom: 5px;
}

div#sbf-overlay-quick-message-content div{
        margin-bottom: 25px;
}
